import React from 'react'
import { graphql } from 'gatsby'
import { Row, Col } from 'reactstrap'

import Layout from '../components/layout'
import { SEO } from '../components/atoms/seo'

export const query = graphql`
  query ($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        tags
        author {
          first_name
          last_name
        }
        type
        date(formatString: "YYYY-MM-DD")
        image {
          publicURL
        }
      }
      html
      fields {
        slug
      }
      excerpt
    }
  }
`

const Blog = ({ data, props }) => {
  // console.log('blog component: ', data, props)

  return (
    <Layout
      location={data?.markdownRemark?.fields?.slug}
      pageType={data?.markdownRemark?.frontmatter?.type}
    >
      <Row className="heading">
        <Col
          xs={{ size: 10, offset: 1 }}
          md={{ size: 8, offset: 2 }}
          lg={{ size: 6, offset: 3 }}
        >
          <h1>{data?.markdownRemark?.frontmatter?.title}</h1>
        </Col>
      </Row>

      <Row className="content">
        <Col
          xs={{ size: 10, offset: 1 }}
          md={{ size: 8, offset: 2 }}
          lg={{ size: 6, offset: 3 }}
        >
          <div className="post-meta">
            <div className="date">
              {data?.markdownRemark?.frontmatter?.date}
            </div>
            <div className="author-name">{`by ${data?.markdownRemark?.frontmatter?.author.first_name} ${data?.markdownRemark?.frontmatter?.author.last_name}`}</div>
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: data?.markdownRemark?.html }}
          ></div>
        </Col>
      </Row>
    </Layout>
  )
}

export default Blog

export const Head = ({ data }) => {
  const pageMeta = {
    title: data?.markdownRemark?.frontmatter?.title,
    type: 'post',
    description: data?.markdownRemark?.excerpt,
    keywords: data?.markdownRemark?.frontmatter?.tags,
    location: data?.markdownRemark?.fields?.slug,
    image: data?.markdownRemark?.frontmatter?.image?.publicURL,
    url: `${data?.site?.siteMetadata?.siteUrl}${data?.markdownRemark?.fields?.slug}`,
  }
  return <SEO meta={{ ...pageMeta }} />
}
